<template>
  <section class="main-body-wrapper">
    <div v-if="processing" class="container p-0">
      <div class="form-wrapper review-account">
        <div class="container-fluid">
          <h1>Please wait while we review your account</h1>
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
            <div class="bounce4"></div>
            <div class="bounce5"></div>
            <div class="bounce6"></div>
          </div>
          <p>
            Do NOT close this page while we process your information.
            <br />This process can take up to 2 minutes
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getBrowseInfo } from '@/helpers/utils';

export default {
  name: 'Checks',
  props: {
    ack: { type: Boolean, default: false }
  },
  data() {
    return {
      checkStatus: 'Checking Identity..',
      token: localStorage.getItem('token'),
      processing: true,
      checkResult: null
    };
  },
  beforeRouteEnter(to, from, next) {
    // if (!to.params.ack) return next({ path: `${to.params.spProductSlug}/welcome` });
    next();
  },
  mounted() {
    this.submitApp();
  },
  filters: {
    pretty: function (value) {
      return JSON.stringify(value, null, 2);
    }
  },
  methods: {
    async submitApp() {
      this.checkStatus = 'Setting up account..';
      let ipInfo = null;
      try {
        ipInfo = await getBrowseInfo();
      } catch (error) {
        ipInfo = {};
      }
      try {
        const response = await this.$axios({
          url: '/onboarding/submit',
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: 'Bearer ' + this.token
          },
          data: { browserInfo: ipInfo, ack: this.ack }
        });
        this.checkResult = response.data;
        this.processing = false;
        this.next();
      } catch (e) {
        const errorMessage =
          e.response && e.response.data.message
            ? e.response.data.message
            : 'Server side error, please contact the support team.';
        let logObject = {
          module: 'onboarding',
          error: errorMessage,
          details: {
            page: 'checks.submitApp',
            endpoint: '/onboarding/submit'
          }
        };
        console.log(JSON.stringify(logObject));
        this.$store.commit('LOG_ERROR', errorMessage);
        this.checkResult = 'AccountNotApproved';
        this.processing = false;
        if (e.response?.status === 401) {
          this.$router.replace({ name: 'GetStarted' }).catch(() => {});
        } else {
          this.next();
        }
      }
    },
    next: function () {
      if (this.processing === false) {
        let nextStep =
          this.checkResult.status === 'active' ? 'AccountApproved' : 'AccountNotApproved';
        this.$store.commit('MARK_STEP_COMPLETED', 5); // Updated from setStepProgress
        this.$store.commit('SET_FLOW_STATUS', {
          flowCompleted: true,
          flowStatus: nextStep
        });
        this.$router
          .push({ name: nextStep, params: { _normalPush: true } })
          .catch(() => {});
      }
    },
    back: function () {
      this.$router
        .push({ name: 'ConfirmProfile', params: { _normalPush: true } })
        .catch(() => {});
    }
  }
};
</script>
