<template>
  <div id="simple-password-input">
    <BaseCustomInput
      :label="label"
      :required="required"
      :error="error || validationError"
      :value="password"
      :type="isPasswordVisible ? 'text' : 'password'"
      :addonLeftIcon="addonLeftIcon"
      @keydown.enter.prevent="handleEnter"
      @input="handleInput"
      @blur="handleBlur"
      @focus="handleFocus"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:addonRight>
        <div
          @click="togglePasswordVisibility"
          class="addon-right input-group-text"
          :class="{
            'error-brdr': touched && (error || validationError),
            'success-brdr': touched && wasValidated && !(error || validationError),
            focused: isFocused
          }"
        >
          <i :class="isPasswordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
        </div>
      </template>
    </BaseCustomInput>
    <div
      :class="{
        'error-margin': error || validationError,
        'no-error-margin': !(error || validationError)
      }"
      class="password-strength-indicator"
      aria-live="polite"
    >
      <div
        v-for="index in 6"
        :key="index"
        class="strength-bar"
        :class="{ active: passwordStrength >= index }"
      ></div>
    </div>
    <ul v-if="passwordFocused" class="password-requirements">
      <li :class="{ fulfilled: hasUpperCase }">Contains an uppercase letter</li>
      <li :class="{ fulfilled: hasNumber }">Contains a number</li>
      <li :class="{ fulfilled: hasSpecialChar }">Contains a special character</li>
      <li :class="{ fulfilled: isLongEnough }">At least 12 characters long</li>
    </ul>
  </div>
</template>

<script>
import BaseCustomInput from './BaseCustomInput.vue';
import { debounce } from '@/helpers/utils';

export default {
  components: {
    BaseCustomInput
  },
  name: 'SimplePasswordInput',
  inheritAttrs: false,
  props: {
    label: String,
    required: Boolean,
    error: String,
    value: String,
    addonLeftIcon: String,
    hasValidation: Boolean
  },
  data() {
    return {
      password: this.value,
      isPasswordVisible: false,
      passwordStrength: 0,
      hasUpperCase: false,
      hasNumber: false,
      hasSpecialChar: false,
      isLongEnough: false,
      validationError: '',
      touched: false,
      isFocused: false,
      wasValidated: false,
      passwordFocused: false
    };
  },
  created() {
    this.debouncedCheckPasswordStrength = debounce(this.checkPasswordStrength, 300);
  },
  watch: {
    value(newValue) {
      this.password = newValue;
      if (newValue) {
        this.debouncedCheckPasswordStrength(newValue);
      }
    }
  },
  mounted() {
    this.password = this.value;
    if (this.password) {
      this.debouncedCheckPasswordStrength(this.password);
    }
  },
  methods: {
    handleInput(value) {
      this.updatePassword(value);
      this.setTouched();
      this.wasValidated = true; // Add this line
    },
    handleBlur() {
      this.setTouched();
      this.isFocused = false;
    },
    handleFocus() {
      this.isFocused = true;
      this.passwordFocused = true;
    },
    setTouched() {
      if (!this.touched) {
        this.touched = true;
      }
    },
    async handleEnter() {
      if (await this.isPasswordValid()) this.$emit('enter-pressed');
    },
    updatePassword(value) {
      this.password = value;
      this.checkPasswordStrength(this.password);
      this.validationError = ''; // Reset validation error on password update
      this.$emit('input', value);
      this.setTouched();
    },
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    checkPasswordStrength(password) {
      try {
        if (!password) {
          this.resetPasswordStrength();
          return false;
        }

        const scores = {
          lengthScore: password.length >= 12 ? 2 : 0,
          upperCaseScore: /[A-Z]/.test(password) ? 1 : 0,
          numberScore: /\d/.test(password) ? 1 : 0,
          specialCharScore: /[!@#$%^&*(),.?":{}|<>]/.test(password) ? 1 : 0,
          // Optional scores for UI purposes
          mixedCaseScore: /[a-z]/.test(password) && /[A-Z]/.test(password) ? 1 : 0,
          // Negative scores (punishments)
          noRepeatedSubstringsScore: this.checkRepeatedSubstrings(password) ? -1 : 0,
          sequentialCharsScore: this.checkSequentialChars(password) ? -1 : 0,
          // Additional scores
          lengthBonus: password.length >= 16 ? 1 : 0,
          complexityBonus: (/[A-Z]/.test(password) && /[a-z]/.test(password) && /\d/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password)) ? 1 : 0,
        };

        this.passwordStrength = Math.max(
          0,
          Math.min(
            6,
            Object.values(scores).reduce((acc, score) => acc + score, 0)
          )
        );

        this.hasUpperCase = scores.upperCaseScore > 0;
        this.hasNumber = scores.numberScore > 0;
        this.hasSpecialChar = scores.specialCharScore > 0;
        this.isLongEnough = scores.lengthScore > 0;

        // Check if the password meets the required criteria
        const isValid = this.hasUpperCase && this.hasNumber && this.hasSpecialChar && this.isLongEnough;

        this.validationError = isValid
          ? ''
          : 'Password does not meet the required criteria';

        return isValid;
      } catch (error) {
        console.error('Error checking password strength:', error);
        this.validationError = 'An error occurred while checking password strength';
        this.resetPasswordStrength();
        return false;
      }
    },
    checkSequentialChars(password) {
      const sequences = [
        '0123456789',
        'abcdefghijklmnopqrstuvwxyz',
        'qwertyuiopasdfghjklzxcvbnm'
      ];
      const lowerCasePassword = password.toLowerCase();
      for (const sequence of sequences) {
        for (let i = 0; i < sequence.length - 2; i++) {
          if (lowerCasePassword.includes(sequence.substring(i, i + 3))) {
            return true;
          }
        }
      }
      return false;
    },
    checkRepeatedSubstrings(password) {
      return /(.+)\1/.test(password);
    },
    // async externalValidation() {
    //   try {
    //     const { data } = await this.$store.dispatch('ANALYZE_PASSWORD', this.password);
    //     const { feedback } = data;

    //     if (feedback.warning) {
    //       this.validationError = feedback.warning;
    //       return false;
    //     }

    //     return true;
    //   } catch (error) {
    //     console.error('Error during external password validation:', error);
    //     this.validationError = 'An error occurred during password validation';
    //     return false;
    //   }
    // },
    resetPasswordStrength() {
      this.passwordStrength = 0;
      this.hasUpperCase = false;
      this.hasNumber = false;
      this.hasSpecialChar = false;
      this.isLongEnough = false;
      this.validationError = '';
    },
    async isPasswordValid(
      // { usesExternalValidation = false } = {}
    ) {
      this.touched = true;
      this.wasValidated = true;
      if (this.password.length === 0) {
        this.validationError = 'Password is required';
        return false;
      }

      const isValid = this.checkPasswordStrength(this.password);

      if (!isValid) {
        this.validationError = 'Password is not strong enough';
      }

      // if (usesExternalValidation) {
      //   return await this.externalValidation();
      // }

      return isValid;
    }
  }
};
</script>

<style lang="scss">
#simple-password-input {
  .password-requirements {
    list-style: none;
    padding: 0;
    margin-top: 10px;

    li {
      margin-bottom: 5px;
      color: #666;
      display: flex;
      align-items: center;

      &::before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        margin-right: 5px;
        width: 1em;
        text-align: center;
      }

      &.fulfilled::before {
        content: '\f00c';
        color: #4caf50;
      }

      &:not(.fulfilled)::before {
        content: '\f00d';
        color: #f44336;
      }
    }
  }

  .password-strength-indicator {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &.error-margin {
      margin-top: 10px;
    }
    &.no-error-margin {
      margin-top: -15px;
    }

    .strength-bar {
      width: 14.28%;
      height: 5px;
      background-color: #ccc;

      &.active {
        &:nth-child(1) {
          background-color: #f44336;
        }
        &:nth-child(2) {
          background-color: #ff9800;
        }
        &:nth-child(3) {
          background-color: #ffc107;
        }
        &:nth-child(4) {
          background-color: #8bc34a;
        }
        &:nth-child(5) {
          background-color: #4caf50;
        }
        &:nth-child(6) {
          background-color: #2e7d32;
        }
      }
    }
  }

  .addon-right.input-group-text {
    cursor: pointer;
    border-radius: 0 4px 4px 0 !important;
    font-size: 16px;
    font-weight: 200;
    line-height: 23px;
    color: #bec1cd;
    border: 1px solid;
    border-left: 0;
    background-color: transparent;
    background-image: none;
    padding: 8px;

    transition: all 0.03s ease-in-out;

    &.error-brdr {
      border-color: #ff151f;
      color: #ff151f;
    }

    &.success-brdr {
      border-color: #4fce3e;
      color: #4fce3e;
    }

    &.focused {
      background: rgba(96, 127, 127, 0.07) !important;
      border-color: rgb(103, 103, 103) !important;
    }
  }

  .form-control {
    border-radius: 4px 0 0 4px !important;
    border-right: 0;
  }
}
</style>
