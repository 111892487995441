var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{attrs:{"id":"header"}},[_c('div',{attrs:{"id":"logo-container"}},[_c('img',{attrs:{"src":_vm.logoImgUrl,"alt":"Product"},on:{"load":_vm.setBackground}})]),_c('nav',{staticClass:"header-progress"},[_c('div',{staticClass:"flex-spacer"}),_c('div',{ref:"steps-container",staticClass:"steps-container"},[_c('ul',{staticClass:"progress-steps"},_vm._l((_vm.stepsWithArrows),function(item,index){return _c('li',{key:index,ref:item.type === 'step' ? `step-${item.originalIndex}` : undefined,refInFor:true,class:{
            'progress-step': item.type === 'step',
            'arrow-step': item.type === 'arrow',
            visited: item.isVisited && item.type === 'step',
            'non-clickable': index === 0 && item.type === 'step'
          },on:{"click":function($event){index !== 0 && item.isVisited
              ? _vm.navigateTo(item.routeNames[0], item.originalIndex)
              : null}}},[(item.type === 'step')?[_c('i',{ref:`icon-${item.originalIndex}`,refInFor:true,staticClass:"d-block fa-fw",class:item.icon,style:({ 'margin-right': item.gap + 'px' })}),_c('span',[_vm._v(_vm._s(item.name))])]:_vm._e(),(item.type === 'arrow')?[_c('i',{staticClass:"fa fa-arrow-right",class:{ 'arrow-spacing': true }})]:_vm._e()],2)}),0)]),_c('div',{staticClass:"flex-spacer"}),_c('div',{staticClass:"progress-container"},[_c('div',{staticClass:"progress-line",style:({ width: _vm.progressWidth })})])]),_c('div',{staticClass:"flex-spacer"})])
}
var staticRenderFns = []

export { render, staticRenderFns }