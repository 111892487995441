<template>
  <form class="d-flex flex-column column-gap-3 form-content" ref="form-content" v-scrollbar>
    <BaseCustomInput
      ref="input-first-name"
      label="First name"
      data-vv-as="First name"
      required
      :name="`firstName${index}`"
      :error="getError(`firstName${index}`, 'First name')"
      v-model="formValues.firstName"
      v-validate="modelValidations.firstName"
      @blur="handleInputBlur"
      @keydown.enter.prevent="focusNext('input-first-name', 'input-middle-name')"
    />
    <BaseCustomInput
      ref="input-middle-name"
      label="Middle name"
      data-vv-as="Middle name"
      :name="`middleName${index}`"
      :error="getError(`middleName${index}`, 'Middle name')"
      v-model="formValues.middleName"
      v-validate="modelValidations.middleName"
      @blur="handleInputBlur"
      @keydown.enter.prevent="focusNext('input-middle-name', 'input-last-name')"
    />
    <BaseCustomInput
      ref="input-last-name"
      label="Last name"
      data-vv-as="Last name"
      required
      :name="`lastName${index}`"
      :error="getError(`lastName${index}`, 'Last name')"
      v-model="formValues.lastName"
      v-validate="modelValidations.lastName"
      @blur="handleInputBlur"
      @keydown.enter.prevent="focusNext('input-last-name', 'input-title')"
    />
    <BaseSelect
      ref="input-title"
      label="Title"
      data-vv-as="Title"
      required
      hasValidation
      :name="`title${index}`"
      :error="getError(`title${index}`, 'Title')"
      :options="titleOptions"
      v-model="formValues.title"
      v-validate="modelValidations.title"
      @blur="handleInputBlur"
      @switch-focus="focusNext('input-title', 'position-input')"
    />
    <BaseSelect
      ref="position-input"
      label="Position"
      data-vv-as="Position"
      required
      hasValidation
      :name="`position${index}`"
      :options="positionOptions"
      :error="getError(`position${index}`)"
      v-model="formValues.position"
      v-validate="modelValidations.position"
      @blur="handleInputBlur"
      @switch-focus="focusNext('position-input', 'numeric-input')"
    />
    <BaseNumericInput
      v-if="type === IDENTITY_TYPE.BUSINESS"
      ref="numeric-input"
      label="Ownership Percentage"
      :name="`ownershipPercentage${index}`"
      :error="getError(`ownershipPercentage${index}`, 'Ownership')"
      v-model="formValues.ownershipPercentage"
      :min="0"
      :max="100"
      :precision="0"
      currency="%"
      currencyPosition="suffix"
      data-vv-as="Ownership Percentage"
      required
      hasValidation
      v-validate="modelValidations.ownershipPercentage"
      @blur="handleInputBlur"
      @switch-focus="focusNext('numeric-input', 'input-date-of-birth')"
    />
    <BaseDatePicker
      ref="input-date-of-birth"
      class="mb-0"
      label="Date of Birth"
      data-vv-as="Date of Birth"
      value-type="format"
      required
      :name="`birthDate${index}`"
      :format="PRETTY_DATE_FORMAT_DP"
      :not-before="minBirthDate"
      :not-after="maxBirthDate"
      :error="getError(`birthDate${index}`, 'Date of Birth')"
      :showCalendarIcon="false"
      v-model="formattedBirthDate"
      v-validate="modelValidations.birthDate"
      @blur="handleInputBlur"
      @switch-focus="focusNext('input-date-of-birth', 'input-phone')"
    />
    <BasePhoneInput
      ref="input-phone"
      label="Phone"
      data-vv-as="Phone number"
      required
      :name="`phoneNumber${index}`"
      :error="getError(`phoneNumber${index}`, 'Phone number')"
      v-model="formValues.phoneNumber"
      v-validate="modelValidations.phoneNumber"
      @blur="handleInputBlur"
      @keydown.enter.prevent="focusNext('input-phone', 'input-email')"
    />
    <BaseCustomInput
      ref="input-email"
      label="Email"
      data-vv-as="Email"
      required
      :name="`email${index}`"
      :error="getError(`email${index}`, 'Email')"
      v-model="formValues.email"
      v-validate="modelValidations.email"
      @blur="handleInputBlur"
      @keydown.enter.prevent="focusNext('input-email', 'input-address')"
    />
    <BaseCustomInput
      ref="input-address"
      label="Address"
      data-vv-as="Address"
      required
      :name="`address${index}`"
      :error="getError(`address${index}`, 'Address')"
      v-model="formValues.address"
      v-validate="modelValidations.address"
      @blur="handleInputBlur"
      @keydown.enter.prevent="focusNext('input-address', 'input-city')"
    />
    <BaseCustomInput
      ref="input-city"
      label="City"
      data-vv-as="City"
      required
      :name="`city${index}`"
      :error="getError(`city${index}`)"
      v-model="formValues.city"
      v-validate="modelValidations.city"
      @blur="handleInputBlur"
      @keydown.enter.prevent="focusNext('input-city', 'input-state')"
    />
    <BaseSelect
      ref="input-state"
      label="State"
      data-vv-as="State"
      required
      hasValidation
      :name="`state${index}`"
      :options="states"
      :error="getError(`state${index}`, 'State')"
      v-model="formValues.state"
      v-validate="modelValidations.state"
      @switch-focus="focusNext('input-state', 'input-zip')"
    />
    <BaseCustomInput
      ref="input-zip"
      label="Zip Code"
      data-vv-as="Zip Code"
      required
      :name="`postalCode${index}`"
      :error="getError(`postalCode${index}`, 'Zip Code')"
      v-maska="'#####'"
      v-model="formValues.postalCode"
      v-validate="modelValidations.postalCode"
      @blur="handleInputBlur"
      @keydown.enter.prevent="focusNext('input-zip', 'input-country')"
    />
    <BaseSelect
      ref="input-country"
      label="Country"
      data-vv-as="Country"
      required
      disabled
      :name="`country${index}`"
      :options="[{ value: 'US', label: 'US' }]"
      v-model="formValues.country"
      addonRightIcon="fa fa-lock"
      @switch-focus="focusNext('input-country', 'input-ssn')"
    />
    <BaseCustomInput
      style="z-index: 2"
      ref="input-ssn"
      label="Social Security number (SSN)"
      data-vv-as="SSN"
      required
      :name="`ssn${index}`"
      :error="getError(`ssn${index}`, 'SSN')"
      v-maska="'###-##-####'"
      v-model="formValues.ssn"
      v-validate="modelValidations.ssn"
      @blur="handleInputBlur"
      @keydown.enter.prevent="submitForm"
    />
  </form>
</template>

<script>
import { scrollToElement } from '@/plugins/perfect-scrollbar';
import {
  mustHaveStreetName,
  mustHaveStreetNumber,
  validSSN,
  validPhoneFormat,
  validzipcodeRegex
} from '@/helpers/CustomValidations';
import { DateTime } from 'luxon';
import BaseNumericInput from '@/components/BaseNumericInput.vue';
import {
  IDENTITY_TYPE,
  OWNERSHIP_TYPES,
  PRETTY_DATE_FORMAT_DP,
  PRETTY_DATE_FORMAT,
  DB_COMPLETE_DATE_FORMAT,
  US_STATES
} from '../helpers/constants';

export default {
  name: 'IndividualSubForm',
  components: { BaseNumericInput },
  inject: ['$validator'],
  props: ['type', 'person', 'index', 'ownershipType'],
  data() {
    return {
      states: US_STATES.map(s => ({ label: s, value: s })),
      formValues: {
        country: 'US'
      },
      IDENTITY_TYPE,
      OWNERSHIP_TYPES,
      titleOptions: [
        { label: 'Mr.', value: 'Mr.' },
        { label: 'Ms.', value: 'Ms.' },
        { label: 'Mrs.', value: 'Mrs.' }
      ],
      PRETTY_DATE_FORMAT_DP
    };
  },
  computed: {
    minBirthDate() {
      return DateTime.now().minus({ years: 100 }).toJSDate();
    },
    maxBirthDate() {
      return DateTime.now().minus({ years: 18 }).toJSDate();
    },
    formattedBirthDate: {
      get() {
        let { birthDate } = this.formValues;

        if (!birthDate) {
          console.error('birthDate is undefined or null');
          return '';
        }

        const fromDBFormat = DateTime.fromFormat(birthDate, DB_COMPLETE_DATE_FORMAT);
        const fromPrettyFormat = DateTime.fromFormat(birthDate, PRETTY_DATE_FORMAT);

        if (fromDBFormat.isValid) {
          return fromDBFormat.toFormat(PRETTY_DATE_FORMAT);
        } else if (!fromPrettyFormat.isValid) {
          console.error('Invalid date format', birthDate);
        }

        return birthDate;
      },
      set(value) {
        this.$set(this.formValues, 'birthDate', value);
      }
    },
    modelValidations() {
      return {
        firstName: { required: true, max: 15 },
        middleName: { max: 15 },
        lastName: { required: true, max: 15 },
        title: { required: true, max: 40 },
        birthDate: { required: true },
        phoneNumber: { required: true, validPhoneFormat: true },
        position: { required: true },
        email: { required: true, email: true, max: 40 },
        address: {
          required: true,
          mustHaveStreetNumber: true,
          mustHaveStreetName: true,
          max: 40
        },
        city: { required: true, max: 28 },
        state: { required: true },
        postalCode: { required: true, regex: validzipcodeRegex },
        ssn: { required: true, regex: validSSN },
        ownershipPercentage: { between: [1, 100] }
      };
    },
    positionOptions() {
      switch (this.ownershipType) {
        case OWNERSHIP_TYPES.PRIVATE_CORP:
        case OWNERSHIP_TYPES.LLC:
        case OWNERSHIP_TYPES.NON_PROFIT:
        case OWNERSHIP_TYPES.PARTNERSHIP:
        case OWNERSHIP_TYPES.TRUST:
        case OWNERSHIP_TYPES.PUBLIC_CORP:
        case OWNERSHIP_TYPES.LIMITED_PARTNERSHIP:
        case OWNERSHIP_TYPES.LIMITED_LIABILITY_PARTNERSHIP:
          return [
            { value: 'OW', label: 'Owner' },
            { value: 'CEO', label: 'CEO' },
            { value: 'CFO', label: 'CFO' },
            { value: 'COO', label: 'COO' },
            { value: 'PRESIDENT', label: 'President' },
            { value: 'VICE PRESIDENT', label: 'Vice President' },
            { value: 'DIR', label: 'Director' },
            { value: 'TR', label: 'Treasurer' },
            { value: 'SEC', label: 'Secretary' },
            { value: 'PA', label: 'Partner' },
            { value: 'MEMBER LLC', label: 'Member LLC' },
            { value: 'OTH', label: 'Other' }
          ];
        case OWNERSHIP_TYPES.SOLE_PROP:
          return [{ value: 'SP', label: 'Sole Proprietorship' }];
        default:
          return [];
      }
    }
  },
  created() {
    const hasStreetNum = this.person.streetNum != null;
    const hasStreetName = this.person.streetName != null;
    const addressValue = hasStreetNum && hasStreetName
      ? `${this.person.streetNum} ${this.person.streetName}`
      : this.person.address || '';

    this.formValues = {
      ...this.person,
      address: addressValue,
      country: 'US',
      ownershipPercentage: this.person.ownershipPercentage || 0
    };
    this.$validator.extend(validPhoneFormat.name, validPhoneFormat.obj);
    this.$validator.extend(mustHaveStreetName.name, mustHaveStreetName.obj);
    this.$validator.extend(mustHaveStreetNumber.name, mustHaveStreetNumber.obj);
  },
  mounted() {
    if (this.$route.params.spProductSlug.slice(-5) === '-demo') {
      for (const key in this.$refs) {
        if (key.indexOf('input-') > -1) this.$refs[key]._data.touched = true;
      }
    }
    if (this.positionOptions[0]?.value != null) {
      this.formValues.position = this.positionOptions[0].value;
    }
    this.$refs['input-first-name'].setFocus();
    this.$nextTick(() => {
      const inputs = this.$el.querySelectorAll('input, select, textarea, button');
      inputs.forEach(input => {
        input.addEventListener('focus', this.handleFocus);
      });
    });
  },
  methods: {
    getData() {
      return this.formValues;
    },
    handleInputBlur() {
      this.$emit('onInputBlur');
    },
    getError(fieldName, fieldLabel) {
      return this.errors.first(fieldName)
        ? this.errors.first(fieldName).replace(` ${fieldLabel}`, '')
        : null;
    },
    submitForm() {
      this.$emit('onSubmit');
    },
    async focusNext(currentRef, nextRef) {
      const currentInput = this.$refs[currentRef];
      if (currentInput && currentInput.$el) {
        const inputElement = currentInput.$el.querySelector('input') || currentInput.$el;
        const inputName = inputElement.getAttribute('name');
        if (inputName) {
          const isValid = await this.$validator.validate(inputName);
          if (!isValid) {
            return;
          }
        }
      }
      this.$nextTick(() => {
        const refElement = this.$refs[nextRef];
        if (!refElement) {
          return;
        }

        if (refElement.$options && refElement.$options.name === 'BaseDatePicker') {
          refElement.focusDatePickerInput();
          return;
        }
        const targetElement = refElement.$el || refElement;
        const nextFocusableElement = this.findNextFocusableElement(targetElement);
        if (nextFocusableElement) {
          nextFocusableElement.focus();
        }
      });
    },
    findNextFocusableElement(element) {
      const findFocusable = el => {
        while (el) {
          if (
            ['INPUT', 'SELECT', 'BUTTON'].includes(el.tagName) &&
            !el.disabled &&
            !el.classList.contains('is-disabled')
          ) {
            return el;
          }
          if (el.children && el.children.length > 0) {
            const foundElement = findFocusable(el.children[0]);
            if (foundElement) return foundElement;
          }
          el = el.nextElementSibling;
        }
        return null;
      };

      return findFocusable(element);
    },
    handleFocus(event) {
      const currentElement = event.target;
      // const previousElement = this.getPreviousInput(currentElement);

      if (currentElement) {
        scrollToElement(currentElement);
      }
    },
    // getPreviousInput(currentElement) {
    //   const inputs = Array.from(
    //     this.$el.querySelectorAll('input, select, textarea, button')
    //   );
    //   const currentIndex = inputs.indexOf(currentElement);
    //   return currentIndex > 0 ? inputs[currentIndex - 1] : null;
    // }
  },
  watch: {
    formValues: {
      handler() {
        this.$emit('change', this.formValues);
      },
      deep: true
    },
    positionOptions() {
      this.formValues.position = null;
    }
  }
};
</script>
