export const IDENTITY_TYPE = {
  INDIVIDUAL: 'individual',
  BUSINESS: 'business',
  GOVERNMENT: 'government-public-corp'
};

export const US_STATES = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'ME',
  'MD',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY'
];

// datepicker component uses a different format than luxon
export const PRETTY_DATE_FORMAT_DP = 'MM-DD-YYYY';
export const PRETTY_DATE_FORMAT = 'MM-dd-yyyy';
export const DB_COMPLETE_DATE_FORMAT = 'yyyy-MM-dd';
export const DB_DATETIME_FORMAT_HH_MM_SS = "yyyy-MM-dd'T'HH:mm:ssZZ";

export const SUBMERCHANT_STATUS = {
  INITIATED: 'initiated',
  BUSINESS_INFO: 'business_info',
  BANK_SELECTED: 'bank_selected',
  PAYOUT: 'payout'
};

export const SM_APPLICATION_STATUS = {
  SMS_VALID: 'sms_valid',
  TYPE_SELECTED: 'type_selected',
  IDENTITY: 'identity',
  SMS_SENT: 'sms_sent',
  INITIATED: 'initiated'
};

export const ENVIRONMENTS = {
  LOCAL: 'local',
  DEV: 'development',
  SANDBOX: 'sandbox',
  PROD: 'production'
};

export const TIN_TYPE_OPTIONS = [
  { value: '1', label: 'SSN' },
  { value: '2', label: 'EIN' },
  { value: '3', label: 'Other' }
];

export const IRS_STATUS_OPTIONS = [
  { label: 'N', value: 'N' },
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
  { label: 'F', value: 'F' },
  { label: 'D', value: 'D' },
  { label: 'C', value: 'C' },
  { label: 'G', value: 'G' },
  { label: 'J', value: 'J' }
];

export const OWNERSHIP_TYPES = {
  PUBLIC_CORP: 'PublicCorporation',
  GOV: 'Government',
  PRIVATE_CORP: 'PrivateCorporation',
  SOLE_PROP: 'SoleProprietorship',
  LLC: 'LLC',
  PARTNERSHIP: 'Partnership',
  NON_PROFIT: 'NonProfit',
  TRUST: 'Trust',
  LIMITED_PARTNERSHIP: "LimitedPartnership",
  LIMITED_LIABILITY_PARTNERSHIP: "LimitedLiabilityPartnership"
};
