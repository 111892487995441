var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"simple-password-input"}},[_c('BaseCustomInput',_vm._g(_vm._b({attrs:{"label":_vm.label,"required":_vm.required,"error":_vm.error || _vm.validationError,"value":_vm.password,"type":_vm.isPasswordVisible ? 'text' : 'password',"addonLeftIcon":_vm.addonLeftIcon},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.handleEnter.apply(null, arguments)},"input":_vm.handleInput,"blur":_vm.handleBlur,"focus":_vm.handleFocus},scopedSlots:_vm._u([{key:"addonRight",fn:function(){return [_c('div',{staticClass:"addon-right input-group-text",class:{
          'error-brdr': _vm.touched && (_vm.error || _vm.validationError),
          'success-brdr': _vm.touched && _vm.wasValidated && !(_vm.error || _vm.validationError),
          focused: _vm.isFocused
        },on:{"click":_vm.togglePasswordVisibility}},[_c('i',{class:_vm.isPasswordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'})])]},proxy:true}])},'BaseCustomInput',_vm.$attrs,false),_vm.$listeners)),_c('div',{staticClass:"password-strength-indicator",class:{
      'error-margin': _vm.error || _vm.validationError,
      'no-error-margin': !(_vm.error || _vm.validationError)
    },attrs:{"aria-live":"polite"}},_vm._l((6),function(index){return _c('div',{key:index,staticClass:"strength-bar",class:{ active: _vm.passwordStrength >= index }})}),0),(_vm.passwordFocused)?_c('ul',{staticClass:"password-requirements"},[_c('li',{class:{ fulfilled: _vm.hasUpperCase }},[_vm._v("Contains an uppercase letter")]),_c('li',{class:{ fulfilled: _vm.hasNumber }},[_vm._v("Contains a number")]),_c('li',{class:{ fulfilled: _vm.hasSpecialChar }},[_vm._v("Contains a special character")]),_c('li',{class:{ fulfilled: _vm.isLongEnough }},[_vm._v("At least 12 characters long")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }